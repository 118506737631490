<template>
  <div class="inspection pb-3" style="height: auto">
    <div id="InspectionArea" class="lable">
      <!-- 標題 -->
      <div class="single-page-tag mb-3 noPrint">
        <p class="sp-start sp-text fw-bolder">撿貨總表</p>
      </div>
      <!-- 操作 -->
      <div class="text-end p-3 noPrint">
        <button class="tw-btn tw-btn-success me-3" @click="print">
          緊湊列印
        </button>
        <button class="tw-btn tw-btn-secondary" @click="close">關閉</button>
      </div>
      <div class="tw-container">
        <!-- 列表 -->
        <div id="Inspection">
          <img
            class="shot-icon mb-2 noPrint"
            src="@/assets/icon/camera.png"
            alt="截圖"
            name="shotCamera"
            @click="shot"
          />
          <ul class="fw-bolder">
            <li class="border-bottom mb-2" v-for="data in list" :key="data[0]">
              <!-- 圖片 -->
              <div class="img-block" style="vertical-align: text-top">
                <Image
                  :imageHash="data[1].merchandiseImgHash"
                  size="s"
                  :lazy="false"
                  v-if="data[1].merchandiseImgHash"
                  :alt="data[1].merchandiseImgHash"
                ></Image>
                <img src="@/assets/other-images/noImg.png" alt="" v-else />
              </div>
              <!-- 右側內容 -->
              <div class="content-block">
                <div class="row m-0">
                  <!-- 名稱 -->
                  <div class="col-3 p-0 ps-2">
                    <p class="mb-2 text-break">{{ data[1].merchandiseName }}</p>
                    <p class="text-break" v-if="data[1].storageLocation">
                      倉儲: {{ data[1].storageLocation }}
                    </p>
                  </div>
                  <!-- 其他資訊 -->
                  <div class="col-9">
                    <div
                      class="row border-bottom"
                      v-for="style in data[1].styles"
                      :key="style[0]"
                    >
                      <!-- 款式 -->
                      <div class="col-4 p-0 ps-2 d-flex align-items-center">
                        <span class="text-break">{{ style[1].styleName }}</span>
                      </div>
                      <!-- 數量 -->
                      <div class="col-4 p-0 ps-2 d-flex align-items-center">
                        {{ style[1].totalQuantity }}
                      </div>
                      <!-- 勾勾 -->
                      <div class="col-4 p-0 ps-2 d-flex align-items-center">
                        <div class="checking-stamp-box d-inline-block me-2">
                          撿貨
                        </div>
                        <div class="checking-stamp-box d-inline-block">
                          檢核
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="tw-text-size18 text-end p-3">
              總數量: {{ totalQuantity }}個
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- 截圖的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="shotModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">截圖結果</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img :src="shotUrl" alt="" style="height: 100%; width: 100%" />
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ShotName"
              placeholder="輸入檔名"
              v-model="inputShotName"
            />
            <label for="ShotName">輸入檔名</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="saveShot">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 截圖套件
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      // modal
      shotModal: {},
      // data
      serverToken: '',
      storeId: 0,
      billHeaderIds: [],
      list: [],
      totalQuantity: 0,
      shotUrl: '',
      inputShotName: '',
    }
  },
  created() {
    this.initialization()
    this.getBillInfo()
  },
  mounted() {
    this.createModals(['shotModal'])
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.billHeaderIds = this.$route.query.headerIds.split(',')
    },
    getBillInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getBillInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 27,
        ids: this.billHeaderIds,
        methods: '{getBillBodies{getMerchOrder{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles}}}'
      }]
      this.list = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billInfo = res.data[0].objects
            vm.sortOutData(billInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理資料
    sortOutData(billInfo) {
      this.list = []
      this.totalQuantity = 0
      let merchandiseMap = new Map()
      billInfo.forEach(item => {
        item.billBodies.forEach(body => {
          // 計算總數量
          this.totalQuantity += body.quantity
          // 款式
          let styleIds = []
          body.style = ''
          body.merchOrder.merchandiseStyles.forEach(style => {
            styleIds.push(style.id)
            body.style += `${style.name} `
          })
          // 商品圖片
          body.merchandiseImgHash = null
          body.merchOrder.merchandise.merchandisePictureLinks.some(img => {
            if (img.front) return body.merchandiseImgHash = img.imageHash
          })
          // 商品名稱
          body.merchandiseName = body.merchOrder.merchandise.name
          // 依商品紀錄
          if (!merchandiseMap.has(body.merchOrder.merchandiseId)) {
            merchandiseMap.set(body.merchOrder.merchandiseId, {
              merchandiseName: body.merchandiseName,
              merchandiseImgHash: body.merchandiseImgHash,
              storageLocation: body.merchOrder.merchandise.storageLocation,
              styles: new Map()
            })
          }
          // 商品內依款式紀錄
          if (!merchandiseMap.get(body.merchOrder.merchandiseId).styles.has(styleIds.toString())) {
            merchandiseMap.get(body.merchOrder.merchandiseId).styles.set(styleIds.toString(), {
              styleName: body.style,
              totalQuantity: 0
            })
          }
          merchandiseMap.get(body.merchOrder.merchandiseId).styles.get(styleIds.toString()).totalQuantity += body.quantity
        })
      });
      console.log(merchandiseMap)
      this.list = merchandiseMap
      this.$methods.switchLoading('hide')
    },
    // 截圖
    shot() {
      this.$methods.switchLoading('show')
      const vm = this;
      this.shotUrl = ''
      this.inputShotName = ''
      $("[name='shotCamera']").addClass("d-none");
      $(".tw-container").addClass("shot-mode");
      html2canvas(document.getElementById("Inspection"), {
        backgroundColor: "#fff",
        useCORS: true,
        scale: 1, // 因為目前截圖元素為 1200，所以比例要設 1，他在手機上才不會寬度成倍數上漲
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        vm.shotUrl = dataURL;
        vm.shotModal.show();
        $("[name='shotCamera']").removeClass("d-none");
        $(".tw-container").removeClass("shot-mode");
        setTimeout(() => {
          vm.$methods.switchLoading('hide')
        }, 500);
      });
    },
    // 儲存截圖
    saveShot() {
      let now = new Date();
      let nowFormat = this.$methods.moment(now).format("YYYYMMDD-HHmmss");
      let alink = document.createElement("a");
      alink.href = this.shotUrl;
      alink.download = this.inputShotName !== "" ? this.inputShotName : nowFormat; //圖片名
      alink.click();
      this.shotModal.hide();
      this.shotUrl = "";
      this.inputShotName = "";
    },
    // 列印
    print() {
      let printArea = document.getElementById("Inspection");
      print(printArea);
    },
    // 關閉
    close() {
      window.close();
    },
  },
}
</script>